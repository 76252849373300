<template>
    <div>
        <div class="container-fluid wrapper-medium mt-3">
            <div class="row mb-3">
                <div class="col-12">
                    <b-link v-if="isAdmin" class="btn btn-link btn-sm pl-0" :to="{ name: 'OrganisationsEdit', params: { id: organisationId } }">
                        <span class="icon icon-left-open mr-2 pl-0"></span>Organisation edit
                    </b-link>
                    <b-link v-else-if="isOrgSuperAdmin" class="btn btn-link btn-sm pl-0" :to="'/members-management/'">
                        <span class="icon icon-left-open mr-2 pl-0"></span>Members Management
                    </b-link>
                    <h2 class="anim-load1 mt-2">User Family Profiles</h2>
                    <p>View members family profiles.</p>
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="mb-3">Parent & Child profiles</h4>
                            <ListViewer :items="userProfiles"
                                        :perPage="5"
                                        btnLabel="View"
                                        display="parentLastName,parentFirstName,childFirstName"
                                        urlSlug="/profile/"
                                        urlTarget="id"
                                        sortBy="parentLastName"
                                        :showSearch="true" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <Footer />
    </div>
</template>

<script>
    import ListViewer from '@/components/modules/ListViewer';
    export default {
        name: 'AdminProfileView',
        components: {
            ListViewer
        },
        props: ['userId', 'organisationId'],
        computed: {
            isAdmin() {
                return this.$store.state.user.roles.includes('Admin');
            },
            isOrgSuperAdmin() {
                return this.$store.state.user.roles.includes('OrgSuperAdmin');
            }
        },
        data() {
            return {
                userProfiles: []
            }
        },
        async created() {
            await this.getParentProfiles()
            this.$store.state.loadingProgress = false
        },

        methods: {
            getParentProfiles() {
                this.$http({
                    method: 'get',
                    url: `${this.$api.AdminProfilesGetByUserId}/${this.userId}`,
                    headers: { 'Authorization': 'bearer ' + this.$store.state.jwt }
                }) 
                    .then((response) => {
                        this.userProfiles = response.data
                    })
                    .catch((error) => {
                        console.error('Error', error)
                        this.showError('Something went wrong. Please try again.')
                    })
            },
            showError(txt) {
                this.serverError = txt
                this.showDismissibleAlert = true
            },
        }
    }
</script>